import React from 'react';
import Layout from 'components/layout';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const IN_TEST_MODE = false;
const devKey = 'AIzaSyDkzXLFZDugeyVpy8t79WmA8aGrfbUzXRA';
const prodKey = 'AIzaSyAuRQdfZhmProPyPHzFv5g1lFB4RrAS9Oo';
const googleMapsApiKey = IN_TEST_MODE ? devKey : prodKey;

// eslint-disable-next-line
import bgImage from 'images/about.png';

const center = { lat: 39.850424, lng: -75.002396 };

const LocationPage = () => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey,
    });

    return isLoaded ? (
        <Layout>
            <div className="fluid-container page-container location-container">
                <div className="map-canvas">
                    <GoogleMap
                        center={center}
                        mapContainerStyle={{ height: `796px` }}
                        options={{
                            disableDefaultUI: true,
                            disableDoubleClickZoom: true,
                        }}
                        zoom={14}
                    >
                        <Marker
                            position={{ lat: 39.851148, lng: -74.981322 }}
                            onClick={() => {
                                window.location =
                                    'https://www.google.com/maps/dir//Haircut-One-Hundred,+700+County+Rte+561,+Voorhees+Township,+NJ+08043/@39.8510238,-74.9835505,17z/data=!3m1!5s0x89c6cd2642b35799:0xef822e582f8ca7d0!4m16!1m6!3m5!1s0x89c6ccdee2231189:0xe4c2c0eda7aae431!2sHaircut-One-Hundred!8m2!3d39.8510197!4d-74.9813618!4m8!1m0!1m5!1m1!1s0x89c6ccdee2231189:0xe4c2c0eda7aae431!2m2!1d-74.9813618!2d39.8510197!3e2';
                            }}
                        />
                    </GoogleMap>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="location-content">
                            <div className="location-text-content">
                                <h3 className="text-bottomless text-uppercase">
                                    Book an appointment today
                                </h3>
                                <h4>
                                    <span className="tel">856.346.1416</span>
                                </h4>
                            </div>
                            <a
                                className="map-link"
                                href="https://www.google.com/maps/dir//Haircut-One-Hundred,+Eagle+Plaza+Shopping+Center,+700+County+Rte+561,+Voorhees+Township,+NJ+08043,+United+States/@39.851303,-74.981225,17z/data=!4m12!1m3!3m2!1s0x89c6ccdee2231189:0xe4c2c0eda7aae431!2sHaircut-One-Hundred!4m7!1m0!1m5!1m1!1s0x89c6ccdee2231189:0xe4c2c0eda7aae431!2m2!1d-74.981225!2d39.851303"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img
                                    className="map-image"
                                    src="https://maps.googleapis.com/maps/api/staticmap?center=39.851148,-74.981322&zoom=15&size=500x500&maptype=roadmap&markers=colorred%7Clabel:A%7C39.851148,-74.981322"
                                    alt="Haircut One Hundred Location"
                                />
                            </a>
                            <div className="location-text-content">
                                <p className="adr">
                                    <span className="street-address">
                                        700 Haddonfield-Berlin Road
                                        <br />
                                        13 Eagle Plaza
                                    </span>
                                    <br />
                                    <span className="locality">
                                        Voorhees
                                    </span>, <span className="region">NJ</span>{' '}
                                    <span className="postal-code">08043</span>
                                </p>
                                <h4>
                                    <a
                                        className="direction-link"
                                        href="https://www.google.com/maps/dir//Haircut-One-Hundred,+Eagle+Plaza+Shopping+Center,+700+County+Rte+561,+Voorhees+Township,+NJ+08043,+United+States/@39.851303,-74.981225,17z/data=!4m12!1m3!3m2!1s0x89c6ccdee2231189:0xe4c2c0eda7aae431!2sHaircut-One-Hundred!4m7!1m0!1m5!1m1!1s0x89c6ccdee2231189:0xe4c2c0eda7aae431!2m2!1d-74.981225!2d39.851303"
                                    >
                                        Directions
                                    </a>
                                </h4>
                                <h3 className="text-bottomless text-uppercase">
                                    Hours
                                </h3>
                                <p className="text-uppercase">
                                    Tue 9-8
                                    <br />
                                    Wed 9-7
                                    <br />
                                    Thu 9-8
                                    <br />
                                    Fri 9-7
                                    <br />
                                    Sat 9-3
                                </p>
                                <p>
                                    Located in Voorhees, NJ Haircut One-Hundred
                                    is located near the main entrance in Eagle
                                    Plaza. With our friendly family atmosphere,
                                    we strive for perfection.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    ) : null;
};

export default React.memo(LocationPage);
